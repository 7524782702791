.container {
	width: 100%;
}
@media (min-width: 640px) {
	.container {
		max-width: 640px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 768px;
	}
}
@media (min-width: 1024px) {
	.container {
		max-width: 1024px;
	}
}
@media (min-width: 1280px) {
	.container {
		max-width: 1280px;
	}
}
@media (min-width: 1536px) {
	.container {
		max-width: 1536px;
	}
}
.visible {
	visibility: visible;
}
.static {
	position: static;
}
.fixed {
	position: fixed;
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.sticky {
	position: sticky;
}
.inset-0 {
	inset: 0px;
}
.bottom-0 {
	bottom: 0px;
}
.left-0 {
	left: 0px;
}
.right-0 {
	right: 0px;
}
.top-0 {
	top: 0px;
}
.z-0 {
	z-index: 0;
}
.z-10 {
	z-index: 10;
}
.z-50 {
	z-index: 50;
}
.m-1 {
	margin: 0.25rem;
}
.m-10 {
	margin: 2.5rem;
}
.m-2 {
	margin: 0.5rem;
}
.m-3 {
	margin: 0.75rem;
}
.m-5 {
	margin: 1.25rem;
}
.-mx-\[16px\] {
	margin-left: -16px;
	margin-right: -16px;
}
.mx-1 {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}
.mx-10 {
	margin-left: 2.5rem;
	margin-right: 2.5rem;
}
.mx-12 {
	margin-left: 3rem;
	margin-right: 3rem;
}
.mx-2 {
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}
.mx-3 {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}
.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem;
}
.mx-5 {
	margin-left: 1.25rem;
	margin-right: 1.25rem;
}
.mx-8 {
	margin-left: 2rem;
	margin-right: 2rem;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.my-1 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}
.my-10 {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}
.my-2 {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
.my-3 {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
}
.my-4 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}
.my-5 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}
.my-6 {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}
.-mb-2 {
	margin-bottom: -0.5rem;
}
.-mt-1 {
	margin-top: -0.25rem;
}
.-mt-2 {
	margin-top: -0.5rem;
}
.-mt-4 {
	margin-top: -1rem;
}
.-mt-5 {
	margin-top: -1.25rem;
}
.-mt-6 {
	margin-top: -1.5rem;
}
.-mt-7 {
	margin-top: -1.75rem;
}
.-mt-\[1px\] {
	margin-top: -1px;
}
.-mt-\[2px\] {
	margin-top: -2px;
}
.-mt-\[3px\] {
	margin-top: -3px;
}
.mb-0 {
	margin-bottom: 0px;
}
.mb-1 {
	margin-bottom: 0.25rem;
}
.mb-10 {
	margin-bottom: 2.5rem;
}
.mb-2 {
	margin-bottom: 0.5rem;
}
.mb-3 {
	margin-bottom: 0.75rem;
}
.mb-4 {
	margin-bottom: 1rem;
}
.mb-5 {
	margin-bottom: 1.25rem;
}
.mb-6 {
	margin-bottom: 1.5rem;
}
.mb-7 {
	margin-bottom: 1.75rem;
}
.mb-8 {
	margin-bottom: 2rem;
}
.mb-\[1px\] {
	margin-bottom: 1px;
}
.ml-0 {
	margin-left: 0px;
}
.ml-1 {
	margin-left: 0.25rem;
}
.ml-10 {
	margin-left: 2.5rem;
}
.ml-2 {
	margin-left: 0.5rem;
}
.ml-3 {
	margin-left: 0.75rem;
}
.ml-4 {
	margin-left: 1rem;
}
.ml-5 {
	margin-left: 1.25rem;
}
.ml-8 {
	margin-left: 2rem;
}
.ml-9 {
	margin-left: 2.25rem;
}
.ml-auto {
	margin-left: auto;
}
.mr-1 {
	margin-right: 0.25rem;
}
.mr-16 {
	margin-right: 4rem;
}
.mr-2 {
	margin-right: 0.5rem;
}
.mr-3 {
	margin-right: 0.75rem;
}
.mr-4 {
	margin-right: 1rem;
}
.mr-5 {
	margin-right: 1.25rem;
}
.mr-\[-3px\] {
	margin-right: -3px;
}
.mr-\[3px\] {
	margin-right: 3px;
}
.mt-1 {
	margin-top: 0.25rem;
}
.mt-10 {
	margin-top: 2.5rem;
}
.mt-12 {
	margin-top: 3rem;
}
.mt-14 {
	margin-top: 3.5rem;
}
.mt-16 {
	margin-top: 4rem;
}
.mt-2 {
	margin-top: 0.5rem;
}
.mt-20 {
	margin-top: 5rem;
}
.mt-24 {
	margin-top: 6rem;
}
.mt-28 {
	margin-top: 7rem;
}
.mt-3 {
	margin-top: 0.75rem;
}
.mt-4 {
	margin-top: 1rem;
}
.mt-40 {
	margin-top: 10rem;
}
.mt-5 {
	margin-top: 1.25rem;
}
.mt-6 {
	margin-top: 1.5rem;
}
.mt-7 {
	margin-top: 1.75rem;
}
.mt-8 {
	margin-top: 2rem;
}
.mt-\[-10px\] {
	margin-top: -10px;
}
.mt-\[-1rem\] {
	margin-top: -1rem;
}
.mt-\[-2\.5rem\] {
	margin-top: -2.5rem;
}
.mt-\[\.5rem\] {
	margin-top: .5rem;
}
.mt-\[1px\] {
	margin-top: 1px;
}
.mt-\[2px\] {
	margin-top: 2px;
}
.mt-\[3px\] {
	margin-top: 3px;
}
.mt-\[7px\] {
	margin-top: 7px;
}
.block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.inline {
	display: inline;
}
.flex {
	display: flex;
}
.inline-flex {
	display: inline-flex;
}
.table {
	display: table;
}
.grid {
	display: grid;
}
.contents {
	display: contents;
}
.list-item {
	display: list-item;
}
.hidden {
	display: none;
}
.h-10 {
	height: 2.5rem;
}
.h-11 {
	height: 2.75rem;
}
.h-12 {
	height: 3rem;
}
.h-14 {
	height: 3.5rem;
}
.h-16 {
	height: 4rem;
}
.h-20 {
	height: 5rem;
}
.h-24 {
	height: 6rem;
}
.h-32 {
	height: 8rem;
}
.h-4 {
	height: 1rem;
}
.h-44 {
	height: 11rem;
}
.h-48 {
	height: 12rem;
}
.h-52 {
	height: 13rem;
}
.h-56 {
	height: 14rem;
}
.h-60 {
	height: 15rem;
}
.h-64 {
	height: 16rem;
}
.h-7 {
	height: 1.75rem;
}
.h-8 {
	height: 2rem;
}
.h-9 {
	height: 2.25rem;
}
.h-\[120px\] {
	height: 120px;
}
.h-\[160px\] {
	height: 160px;
}
.h-\[200px\] {
	height: 200px;
}
.h-\[20px\] {
	height: 20px;
}
.h-\[220px\] {
	height: 220px;
}
.h-\[280px\] {
	height: 280px;
}
.h-\[40px\] {
	height: 40px;
}
.h-\[420px\] {
	height: 420px;
}
.h-\[44px\] {
	height: 44px;
}
.h-\[450px\] {
	height: 450px;
}
.h-\[73\%\] {
	height: 73%;
}
.h-\[93vh\] {
	height: 93vh;
}
.h-auto {
	height: auto;
}
.h-fit {
	height: -moz-fit-content;
	height: fit-content;
}
.h-full {
	height: 100%;
}
.h-screen {
	height: 100vh;
}
.max-h-60 {
	max-height: 15rem;
}
.max-h-\[100\%\] {
	max-height: 100%;
}
.max-h-\[420px\] {
	max-height: 420px;
}
.min-h-\[228px\] {
	min-height: 228px;
}
.min-h-screen {
	min-height: 100vh;
}
.w-1\/2 {
	width: 50%;
}
.w-1\/3 {
	width: 33.333333%;
}
.w-1\/4 {
	width: 25%;
}
.w-1\/6 {
	width: 16.666667%;
}
.w-10 {
	width: 2.5rem;
}
.w-12 {
	width: 3rem;
}
.w-24 {
	width: 6rem;
}
.w-32 {
	width: 8rem;
}
.w-4 {
	width: 1rem;
}
.w-44 {
	width: 11rem;
}
.w-48 {
	width: 12rem;
}
.w-52 {
	width: 13rem;
}
.w-56 {
	width: 14rem;
}
.w-60 {
	width: 15rem;
}
.w-64 {
	width: 16rem;
}
.w-72 {
	width: 18rem;
}
.w-8\/12 {
	width: 66.666667%;
}
.w-\[100px\] {
	width: 100px;
}
.w-\[120px\] {
	width: 120px;
}
.w-\[12rem\] {
	width: 12rem;
}
.w-\[14rem\] {
	width: 14rem;
}
.w-\[17rem\] {
	width: 17rem;
}
.w-\[20px\] {
	width: 20px;
}
.w-\[20rem\] {
	width: 20rem;
}
.w-\[22rem\] {
	width: 22rem;
}
.w-\[300px\] {
	width: 300px;
}
.w-\[30rem\] {
	width: 30rem;
}
.w-\[360px\] {
	width: 360px;
}
.w-\[384px\] {
	width: 384px;
}
.w-fit {
	width: -moz-fit-content;
	width: fit-content;
}
.w-full {
	width: 100%;
}
.w-screen {
	width: 100vw;
}
.min-w-0 {
	min-width: 0px;
}
.min-w-\[23rem\] {
	min-width: 23rem;
}
.min-w-\[24\%\] {
	min-width: 24%;
}
.min-w-\[24rem\] {
	min-width: 24rem;
}
.min-w-\[30rem\] {
	min-width: 30rem;
}
.min-w-\[35\%\] {
	min-width: 35%;
}
.min-w-\[5rem\] {
	min-width: 5rem;
}
.max-w-2xl {
	max-width: 42rem;
}
.max-w-3xl {
	max-width: 48rem;
}
.max-w-4xl {
	max-width: 56rem;
}
.max-w-5xl {
	max-width: 64rem;
}
.max-w-6xl {
	max-width: 72rem;
}
.max-w-7xl {
	max-width: 80rem;
}
.max-w-\[10rem\] {
	max-width: 10rem;
}
.max-w-\[11rem\] {
	max-width: 11rem;
}
.max-w-\[12rem\] {
	max-width: 12rem;
}
.max-w-\[140px\] {
	max-width: 140px;
}
.max-w-\[14rem\] {
	max-width: 14rem;
}
.max-w-\[15rem\] {
	max-width: 15rem;
}
.max-w-\[16rem\] {
	max-width: 16rem;
}
.max-w-\[18rem\] {
	max-width: 18rem;
}
.max-w-\[200px\] {
	max-width: 200px;
}
.max-w-\[23rem\] {
	max-width: 23rem;
}
.max-w-\[250px\] {
	max-width: 250px;
}
.max-w-\[27rem\] {
	max-width: 27rem;
}
.max-w-\[30rem\] {
	max-width: 30rem;
}
.max-w-\[38rem\] {
	max-width: 38rem;
}
.max-w-\[53rem\] {
	max-width: 53rem;
}
.max-w-\[5rem\] {
	max-width: 5rem;
}
.max-w-\[6rem\] {
	max-width: 6rem;
}
.max-w-\[7rem\] {
	max-width: 7rem;
}
.max-w-full {
	max-width: 100%;
}
.max-w-lg {
	max-width: 32rem;
}
.max-w-md {
	max-width: 28rem;
}
.max-w-sm {
	max-width: 24rem;
}
.max-w-xl {
	max-width: 36rem;
}
.max-w-xs {
	max-width: 20rem;
}
.flex-1 {
	flex: 1 1 0%;
}
.shrink-0 {
	flex-shrink: 0;
}
.flex-grow {
	flex-grow: 1;
}
.grow {
	flex-grow: 1;
}
.transform {
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-not-allowed {
	cursor: not-allowed;
}
.cursor-pointer {
	cursor: pointer;
}
.resize {
	resize: both;
}
.list-inside {
	list-style-position: inside;
}
.list-disc {
	list-style-type: disc;
}
.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
	grid-template-rows: repeat(3, minmax(0, 1fr));
}
.flex-row {
	flex-direction: row;
}
.flex-row-reverse {
	flex-direction: row-reverse;
}
.flex-col {
	flex-direction: column;
}
.flex-wrap {
	flex-wrap: wrap;
}
.place-items-center {
	place-items: center;
}
.items-start {
	align-items: flex-start;
}
.items-end {
	align-items: flex-end;
}
.items-center {
	align-items: center;
}
.items-baseline {
	align-items: baseline;
}
.justify-start {
	justify-content: flex-start;
}
.justify-end {
	justify-content: flex-end;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-evenly {
	justify-content: space-evenly;
}
.gap-1 {
	gap: 0.25rem;
}
.gap-10 {
	gap: 2.5rem;
}
.gap-12 {
	gap: 3rem;
}
.gap-14 {
	gap: 3.5rem;
}
.gap-2 {
	gap: 0.5rem;
}
.gap-20 {
	gap: 5rem;
}
.gap-3 {
	gap: 0.75rem;
}
.gap-32 {
	gap: 8rem;
}
.gap-4 {
	gap: 1rem;
}
.gap-5 {
	gap: 1.25rem;
}
.gap-6 {
	gap: 1.5rem;
}
.gap-7 {
	gap: 1.75rem;
}
.gap-8 {
	gap: 2rem;
}
.gap-9 {
	gap: 2.25rem;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1rem * var(--tw-space-x-reverse));
	margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
	--tw-divide-y-reverse: 0;
	border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
	border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.self-start {
	align-self: flex-start;
}
.self-end {
	align-self: flex-end;
}
.self-center {
	align-self: center;
}
.justify-self-end {
	justify-self: end;
}
.overflow-auto {
	overflow: auto;
}
.overflow-hidden {
	overflow: hidden;
}
.overflow-y-auto {
	overflow-y: auto;
}
.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.overflow-ellipsis {
	text-overflow: ellipsis;
}
.text-ellipsis {
	text-overflow: ellipsis;
}
.whitespace-nowrap {
	white-space: nowrap;
}
.whitespace-pre-line {
	white-space: pre-line;
}
.break-words {
	overflow-wrap: break-word;
}
.rounded {
	border-radius: 0.25rem;
}
.rounded-\[4px\] {
	border-radius: 4px;
}
.rounded-full {
	border-radius: 9999px;
}
.rounded-lg {
	border-radius: 0.5rem;
}
.rounded-md {
	border-radius: 0.375rem;
}
.rounded-none {
	border-radius: 0px;
}
.rounded-sm {
	border-radius: 0.125rem;
}
.rounded-xl {
	border-radius: 0.75rem;
}
.rounded-b-lg {
	border-bottom-right-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
}
.rounded-b-md {
	border-bottom-right-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
}
.rounded-l-lg {
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
}
.rounded-l-md {
	border-top-left-radius: 0.375rem;
	border-bottom-left-radius: 0.375rem;
}
.rounded-r-lg {
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}
.rounded-r-md {
	border-top-right-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}
.rounded-t-md {
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}
.rounded-tl-none {
	border-top-left-radius: 0px;
}
.border {
	border-width: 1px;
}
.border-2 {
	border-width: 2px;
}
.border-solid {
	border-style: solid;
}
.border-none {
	border-style: none;
}
.border-sky-500 {
	--tw-border-opacity: 1;
	border-color: rgb(14 165 233 / var(--tw-border-opacity));
}
.border-white {
	--tw-border-opacity: 1;
	border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.bg-\[\#878787\] {
	--tw-bg-opacity: 1;
	background-color: rgb(135 135 135 / var(--tw-bg-opacity));
}
.bg-\[\#F4BB44\] {
	--tw-bg-opacity: 1;
	background-color: rgb(244 187 68 / var(--tw-bg-opacity));
}
.bg-\[\#FFD300\] {
	--tw-bg-opacity: 1;
	background-color: rgb(255 211 0 / var(--tw-bg-opacity));
}
.bg-\[\#c8ad7f\] {
	--tw-bg-opacity: 1;
	background-color: rgb(200 173 127 / var(--tw-bg-opacity));
}
.bg-\[rgba\(33\2c 60\2c 98\2c 0\.85\)\] {
	background-color: rgba(33,60,98,0.85);
}
.bg-\[secondaryColor\] {
	background-color: secondaryColor;
}
.bg-black {
	--tw-bg-opacity: 1;
	background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-blue-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bg-blue-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}
.bg-blue-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}
.bg-blue-800 {
	--tw-bg-opacity: 1;
	background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}
.bg-blue-900 {
	--tw-bg-opacity: 1;
	background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}
.bg-fuchsia-900 {
	--tw-bg-opacity: 1;
	background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}
.bg-gray-200 {
	--tw-bg-opacity: 1;
	background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.bg-gray-300 {
	--tw-bg-opacity: 1;
	background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.bg-gray-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.bg-gray-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.bg-gray-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.bg-gray-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
.bg-gray-800 {
	--tw-bg-opacity: 1;
	background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
.bg-green-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}
.bg-green-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
.bg-green-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.bg-green-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}
.bg-indigo-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
.bg-lime-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}
.bg-lime-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}
.bg-lime-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}
.bg-orange-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}
.bg-orange-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}
.bg-orange-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}
.bg-pink-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}
.bg-pink-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}
.bg-purple-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}
.bg-purple-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}
.bg-purple-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}
.bg-purple-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}
.bg-red-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}
.bg-red-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.bg-red-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.bg-red-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}
.bg-red-800 {
	--tw-bg-opacity: 1;
	background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}
.bg-rose-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}
.bg-slate-100 {
	--tw-bg-opacity: 1;
	background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}
.bg-slate-200 {
	--tw-bg-opacity: 1;
	background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}
.bg-slate-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}
.bg-slate-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}
.bg-slate-900 {
	--tw-bg-opacity: 1;
	background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}
.bg-teal-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}
.bg-violet-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}
.bg-white {
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-yellow-400 {
	--tw-bg-opacity: 1;
	background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}
.bg-yellow-500 {
	--tw-bg-opacity: 1;
	background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}
.bg-yellow-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}
.bg-opacity-50 {
	--tw-bg-opacity: 0.5;
}
.bg-opacity-70 {
	--tw-bg-opacity: 0.7;
}
.object-cover {
	-o-object-fit: cover;
	   object-fit: cover;
}
.p-0 {
	padding: 0px;
}
.p-1 {
	padding: 0.25rem;
}
.p-10 {
	padding: 2.5rem;
}
.p-2 {
	padding: 0.5rem;
}
.p-3 {
	padding: 0.75rem;
}
.p-4 {
	padding: 1rem;
}
.p-5 {
	padding: 1.25rem;
}
.p-\[2px\] {
	padding: 2px;
}
.px-10 {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}
.px-2 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}
.px-3 {
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}
.px-4 {
	padding-left: 1rem;
	padding-right: 1rem;
}
.px-5 {
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}
.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}
.px-7 {
	padding-left: 1.75rem;
	padding-right: 1.75rem;
}
.py-1 {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.py-2 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}
.py-3 {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}
.py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.pb-2 {
	padding-bottom: 0.5rem;
}
.pb-3 {
	padding-bottom: 0.75rem;
}
.pb-4 {
	padding-bottom: 1rem;
}
.pl-0 {
	padding-left: 0px;
}
.pl-3 {
	padding-left: 0.75rem;
}
.pl-4 {
	padding-left: 1rem;
}
.pl-5 {
	padding-left: 1.25rem;
}
.pl-6 {
	padding-left: 1.5rem;
}
.pr-4 {
	padding-right: 1rem;
}
.pt-1 {
	padding-top: 0.25rem;
}
.pt-10 {
	padding-top: 2.5rem;
}
.pt-2 {
	padding-top: 0.5rem;
}
.pt-3 {
	padding-top: 0.75rem;
}
.pt-4 {
	padding-top: 1rem;
}
.pt-5 {
	padding-top: 1.25rem;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.align-baseline {
	vertical-align: baseline;
}
.align-top {
	vertical-align: top;
}
.align-middle {
	vertical-align: middle;
}
.align-bottom {
	vertical-align: bottom;
}
.align-text-bottom {
	vertical-align: text-bottom;
}
.font-classic {
	font-family: 'Cookie', cursive;
}
.font-disco {
	font-family: 'Spicy Rice';
}
.font-edm {
	font-family: 'Audiowide';
}
.font-jazz {
	font-family: 'Satisfy';
}
.font-mono {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.font-rap {
	font-family: Bangers;
}
.font-rock {
	font-family: 'Rock Salt';
}
.text-2xl {
	font-size: 1.5rem;
	line-height: 2rem;
}
.text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem;
}
.text-4xl {
	font-size: 2.25rem;
	line-height: 2.5rem;
}
.text-5xl {
	font-size: 3rem;
	line-height: 1;
}
.text-6xl {
	font-size: 3.75rem;
	line-height: 1;
}
.text-7xl {
	font-size: 4.5rem;
	line-height: 1;
}
.text-8xl {
	font-size: 6rem;
	line-height: 1;
}
.text-9xl {
	font-size: 8rem;
	line-height: 1;
}
.text-\[\.9rem\] {
	font-size: .9rem;
}
.text-\[1\.1rem\] {
	font-size: 1.1rem;
}
.text-\[1\.2rem\] {
	font-size: 1.2rem;
}
.text-\[1\.3rem\] {
	font-size: 1.3rem;
}
.text-\[1\.4rem\] {
	font-size: 1.4rem;
}
.text-\[1\.6rem\] {
	font-size: 1.6rem;
}
.text-\[1\.7rem\] {
	font-size: 1.7rem;
}
.text-\[1\.8rem\] {
	font-size: 1.8rem;
}
.text-\[10px\] {
	font-size: 10px;
}
.text-\[14px\] {
	font-size: 14px;
}
.text-\[15px\] {
	font-size: 15px;
}
.text-\[16px\] {
	font-size: 16px;
}
.text-\[17px\] {
	font-size: 17px;
}
.text-\[1rem\] {
	font-size: 1rem;
}
.text-\[20px\] {
	font-size: 20px;
}
.text-\[21px\] {
	font-size: 21px;
}
.text-\[22px\] {
	font-size: 22px;
}
.text-\[24px\] {
	font-size: 24px;
}
.text-\[5\.5rem\] {
	font-size: 5.5rem;
}
.text-base {
	font-size: 1rem;
	line-height: 1.5rem;
}
.text-lg {
	font-size: 1.125rem;
	line-height: 1.75rem;
}
.text-sm {
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem;
}
.text-xs {
	font-size: 0.75rem;
	line-height: 1rem;
}
.font-bold {
	font-weight: 700;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.uppercase {
	text-transform: uppercase;
}
.italic {
	font-style: italic;
}
.leading-5 {
	line-height: 1.25rem;
}
.leading-6 {
	line-height: 1.5rem;
}
.leading-7 {
	line-height: 1.75rem;
}
.leading-\[2\.5rem\] {
	line-height: 2.5rem;
}
.leading-relaxed {
	line-height: 1.625;
}
.leading-snug {
	line-height: 1.375;
}
.tracking-wide {
	letter-spacing: 0.025em;
}
.text-\[\#228be6\] {
	--tw-text-opacity: 1;
	color: rgb(34 139 230 / var(--tw-text-opacity));
}
.text-\[\#6acc56\] {
	--tw-text-opacity: 1;
	color: rgb(106 204 86 / var(--tw-text-opacity));
}
.text-\[\#967444\] {
	--tw-text-opacity: 1;
	color: rgb(150 116 68 / var(--tw-text-opacity));
}
.text-\[\#B5722F\] {
	--tw-text-opacity: 1;
	color: rgb(181 114 47 / var(--tw-text-opacity));
}
.text-\[\#C0C0C0\] {
	--tw-text-opacity: 1;
	color: rgb(192 192 192 / var(--tw-text-opacity));
}
.text-\[\#D3D3D3\] {
	--tw-text-opacity: 1;
	color: rgb(211 211 211 / var(--tw-text-opacity));
}
.text-\[\#FF5733\] {
	--tw-text-opacity: 1;
	color: rgb(255 87 51 / var(--tw-text-opacity));
}
.text-\[\#FFC800\] {
	--tw-text-opacity: 1;
	color: rgb(255 200 0 / var(--tw-text-opacity));
}
.text-\[\#FFD700\] {
	--tw-text-opacity: 1;
	color: rgb(255 215 0 / var(--tw-text-opacity));
}
.text-\[\#e5f31a\] {
	--tw-text-opacity: 1;
	color: rgb(229 243 26 / var(--tw-text-opacity));
}
.text-\[\#facc15\] {
	--tw-text-opacity: 1;
	color: rgb(250 204 21 / var(--tw-text-opacity));
}
.text-\[\#fcfc24\] {
	--tw-text-opacity: 1;
	color: rgb(252 252 36 / var(--tw-text-opacity));
}
.text-black {
	--tw-text-opacity: 1;
	color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-blue-400 {
	--tw-text-opacity: 1;
	color: rgb(96 165 250 / var(--tw-text-opacity));
}
.text-gray-200 {
	--tw-text-opacity: 1;
	color: rgb(229 231 235 / var(--tw-text-opacity));
}
.text-gray-300 {
	--tw-text-opacity: 1;
	color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
	--tw-text-opacity: 1;
	color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-500 {
	--tw-text-opacity: 1;
	color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-gray-600 {
	--tw-text-opacity: 1;
	color: rgb(75 85 99 / var(--tw-text-opacity));
}
.text-green-400 {
	--tw-text-opacity: 1;
	color: rgb(74 222 128 / var(--tw-text-opacity));
}
.text-green-500 {
	--tw-text-opacity: 1;
	color: rgb(34 197 94 / var(--tw-text-opacity));
}
.text-green-700 {
	--tw-text-opacity: 1;
	color: rgb(21 128 61 / var(--tw-text-opacity));
}
.text-purple-500 {
	--tw-text-opacity: 1;
	color: rgb(168 85 247 / var(--tw-text-opacity));
}
.text-red-500 {
	--tw-text-opacity: 1;
	color: rgb(239 68 68 / var(--tw-text-opacity));
}
.text-red-600 {
	--tw-text-opacity: 1;
	color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-red-700 {
	--tw-text-opacity: 1;
	color: rgb(185 28 28 / var(--tw-text-opacity));
}
.text-slate-300 {
	--tw-text-opacity: 1;
	color: rgb(203 213 225 / var(--tw-text-opacity));
}
.text-white {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-400 {
	--tw-text-opacity: 1;
	color: rgb(250 204 21 / var(--tw-text-opacity));
}
.text-yellow-500 {
	--tw-text-opacity: 1;
	color: rgb(234 179 8 / var(--tw-text-opacity));
}
.underline {
	text-decoration-line: underline;
}
.line-through {
	text-decoration-line: line-through;
}
.opacity-50 {
	opacity: 0.5;
}
.opacity-80 {
	opacity: 0.8;
}
.shadow {
	--tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
	--tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
	outline-style: solid;
}
.blur {
	--tw-blur: blur(8px);
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 150ms;
}
.ease-in-out {
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out {
	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {        
    animation: fake 1s infinite;
}

td{
	text-align: start;
	vertical-align: middle;
}

th{
	font-weight: 600;
	font-size: large;
}

a{
	text-decoration: none;
	color: inherit;
  }

.hover\:cursor-pointer:hover {
	cursor: pointer;
}

.hover\:bg-gray-400:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-slate-300:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.hover\:bg-slate-400:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.hover\:bg-slate-500:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
	--tw-text-opacity: 1;
	color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
	--tw-text-opacity: 1;
	color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-yellow-300:hover {
	--tw-text-opacity: 1;
	color: rgb(253 224 71 / var(--tw-text-opacity));
}

.hover\:underline:hover {
	text-decoration-line: underline;
}

.hover\:shadow-lg:hover {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}

@media (min-width: 640px) {
	.sm\:flex {
		display: flex;
	}
	.sm\:grid {
		display: grid;
	}
	.sm\:w-1\/2 {
		width: 50%;
	}
	.sm\:w-\[390px\] {
		width: 390px;
	}
	.sm\:max-w-\[285px\] {
		max-width: 285px;
	}
	.sm\:grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.sm\:text-base {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	.sm\:text-xl {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}
}

@media (min-width: 768px) {
	.md\:block {
		display: block;
	}
	.md\:flex {
		display: flex;
	}
	.md\:hidden {
		display: none;
	}
	.md\:w-1\/3 {
		width: 33.333333%;
	}
	.md\:w-\[10\%\] {
		width: 10%;
	}
	.md\:w-\[100\%\] {
		width: 100%;
	}
	.md\:w-\[26rem\] {
		width: 26rem;
	}
	.md\:w-\[49\.7\%\] {
		width: 49.7%;
	}
	.md\:basis-1\/3 {
		flex-basis: 33.333333%;
	}
	.md\:basis-2\/3 {
		flex-basis: 66.666667%;
	}
	.md\:justify-start {
		justify-content: flex-start;
	}
	.md\:justify-between {
		justify-content: space-between;
	}
	.md\:gap-0 {
		gap: 0px;
	}
	.md\:px-5 {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
	.md\:text-2xl {
		font-size: 1.5rem;
		line-height: 2rem;
	}
}

@media (min-width: 1024px) {
	.lg\:mt-28 {
		margin-top: 7rem;
	}
	.lg\:block {
		display: block;
	}
	.lg\:flex {
		display: flex;
	}
	.lg\:hidden {
		display: none;
	}
	.lg\:w-1\/3 {
		width: 33.333333%;
	}
	.lg\:w-1\/4 {
		width: 25%;
	}
	.lg\:w-\[32\.5\%\] {
		width: 32.5%;
	}
}

@media (min-width: 1280px) {
	.xl\:w-1\/2 {
		width: 50%;
	}
	.xl\:w-1\/5 {
		width: 20%;
	}
	.xl\:w-\[32\.5\%\] {
		width: 32.5%;
	}
}
